import { lazy } from "react";

const InvoicesList = lazy(() => import("containers/Invoices/List"));
const InvoiceCreate = lazy(() => import("containers/Invoices/Create"));

const CustomersList = lazy(() => import("containers/Customers/List"));
const CustomerCreate = lazy(() => import("containers/Customers/Create"));
const CustomerUpdate = lazy(() => import("containers/Customers/Update"));

const routes = [
  {
    path: "/",
    component: InvoicesList,
  },
  {
    path: "/invoices/new",
    component: InvoiceCreate,
  },
  {
    path: "/customers",
    component: CustomersList,
  },
  {
    path: "/customers/new",
    component: CustomerCreate,
  },
  {
    path: "/customers/:id/edit",
    component: CustomerUpdate,
  },
];

export { routes };
