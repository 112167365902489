import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import useAuth from "context/useAuth";
import { LoadingPage } from "components/shared/LoadingPage";
import { RequireAuth } from "components/shared/RequireAuth";
import { routes } from "./helper";

const Login = lazy(() => import("containers/Login"));
const NotFound = lazy(() => import('containers/NotFound'));

export default function AppRoutes() {
  const { isFetching } = useAuth();
  return isFetching ? (
    <LoadingPage />
  ) : (
    <Routes>
      {routes.map(({ path, component: Component }) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <Component />
            </RequireAuth>
          }
        />
      ))}
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
