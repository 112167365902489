import { CircularProgress, Box, Typography } from "@mui/material";

export const LoadingPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        gap: 2,
      }}
    >
      <CircularProgress thickness={4} size={40} />
      <Typography>Loading...</Typography>
    </Box>
  );
};
