import React from "react";
import Axios from "axios";
import errorResponseHandler from "utils/errorHandler";

export const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [isLogged, setLogged] = React.useState();
  const [fetching, setFetching] = React.useState(true);
  const [user, setUser] = React.useState({});

  const axiosConfig = {
    ...(user.token && { Authorization: `Bearer  ${user.token}` }),
    baseUrl: `${process.env.REACT_APP_BASE_URL}/v1/`,
  };

  const http = Axios.create({
    baseURL: axiosConfig.baseUrl,
    headers: axiosConfig,
  });

  http.interceptors.response.use(null, (error) =>
    errorResponseHandler(error, logOut)
  );

  React.useEffect(() => {
    const usr = JSON.parse(localStorage.getItem("user"));

    setLogged(!!usr);
    setUser(usr || {});
  }, [isLogged]);

  // add loading state to check is data loaded
  React.useEffect(() => {
    setFetching(true);

    const timer = setTimeout(() => {
      setFetching(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isLogged]);

  const logOut = () => {
    localStorage.removeItem("user");
    setLogged(false);
    setUser({});
  };

  const saveUser = (resp) => {
    const usr = {
      token: resp.token,
      ...resp.user,
    };
    localStorage.setItem("user", JSON.stringify(usr));
    setUser(usr);
    setLogged(true);
  };

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        setLogged,
        logOut,
        user,
        saveUser,
        isFetching: fetching,
        http,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
