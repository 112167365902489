import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "context/AuthContext";
import { theme } from "./theme";
import AppRoutes from "./routes/AppRoutes";

import 'react-toastify/dist/ReactToastify.min.css' // minfied version of css file (.min.css)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ToastContainer
          position="top-center"
          autoClose={8000}
          hideProgressBar={false}
          newestOnTop={false}
          draggable={false}
          closeOnClick
          pauseOnHover
          pauseOnFocusLoss
          style={{ minWidth: 500 }}
        />
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
