import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#7c51a0",
    },
    secondary: {
      main: "#e91e63",
    },
    background: {
      default: "#fafafa",
    },
  },
  typography: {
    fontFamily: "Ubuntu",
  },
  shape: {
    borderRadius: 8,
  },
});

export { theme };
