function errorResponseHandler(error, logOut) {
  const { response } = error;

  if (response) {
    switch (response.status) {
      case 401:
        logOut();
        break;

      case 403:
        if (localStorage.getItem("user")) {
          window.location.href = "/";
        }
        break;

      default:
        break;
    }
  }

  return Promise.reject(error);
}

export default errorResponseHandler;
